import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  ContainerCard,
  ContainerList,
  CardTitle,
  SpecialtiesContainer,
  ListTitle,
  ListSubTitle,
  ListMarker,
  ListRoot,
  ListItem,
  StyledImg,
  PhotoContainer,
  QuoteContainer,
  QuoteText,
  SubList,
} from './spotlight.css';

const ListMapped = ({ item }) => {
  return (
    <SubList>
      <ListSubTitle>{item.title}</ListSubTitle>
      {item.therapies.map((specialty, j) => (
        <ListItem key={'item' + j}>
          <ListMarker />
          {specialty}
        </ListItem>
      ))}
    </SubList>
  );
};

ListMapped.propTypes = {
  item: PropTypes.object.isRequired,
};

const Spotlight = ({ image, quote, specialties }) => {
  return (
    <Row>
      <ContainerCard>
        <PhotoContainer>
          <StyledImg
            fluid={image ? image.childImageSharp.fluid : {}}
            alt="Dott.ssa Lara Bartali"
          />
        </PhotoContainer>
        <QuoteContainer>
          <CardTitle>Dott.ssa Lara Bartali</CardTitle>
          <QuoteText>{quote}</QuoteText>
        </QuoteContainer>
      </ContainerCard>
      <ContainerList>
        <ListTitle>Di cosa mi occupo?</ListTitle>
        <SpecialtiesContainer>
          <ListRoot>
            {specialties.map((item, i) => (
              <ListMapped key={i} item={item} />
            ))}
          </ListRoot>
        </SpecialtiesContainer>
      </ContainerList>
    </Row>
  );
};

Spotlight.propTypes = {
  image: PropTypes.object.isRequired,
  quote: PropTypes.string.isRequired,
  specialties: PropTypes.array.isRequired,
};

export default Spotlight;
