import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  ContainerTitle,
  Title,
  StyledBackgroundSection,
  StyledImg,
  StyledButtonsHolder,
  StyledButton,
  MobileLandingSection,
} from './landing.css';

const InnerLandingContent = ({ message, logo, telephone }) => {
  return (
    <ContainerTitle>
      <StyledImg fluid={logo ? logo.childImageSharp.fluid : {}} alt={message} />
      <Title as="h1" size="large">
        {message}
      </Title>
      <StyledButtonsHolder>
        <StyledButton
          href={
            telephone ? telephone : 'mailto:larabartali.fisioterapia@gmail.com'
          }
        >
          Richiedi un colloquio via email
        </StyledButton>
        <StyledButton href="https://wa.me/393393346415">
          Scrivimi su WhatsApp
        </StyledButton>
      </StyledButtonsHolder>
    </ContainerTitle>
  );
};

const Landing = ({ message, image, logo, telephone }) => {
  return (
    <Row>
      <StyledBackgroundSection className="landing-background" image={image}>
        <InnerLandingContent
          message={message}
          logo={logo}
          telephone={telephone}
        ></InnerLandingContent>
      </StyledBackgroundSection>
      <MobileLandingSection>
        <InnerLandingContent
          message={message}
          logo={logo}
          telephone={telephone}
        ></InnerLandingContent>
      </MobileLandingSection>
    </Row>
  );
};

InnerLandingContent.propTypes = {
  message: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  telephone: PropTypes.string,
};

Landing.propTypes = {
  message: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  telephone: PropTypes.string,
};

export default Landing;
