import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  /* width: 100vw; */
  padding: 0 5vw;
  margin-top: 25vh;

  background-color: #222;
  color: #fff;

  ${MEDIA.DESKTOP`
    flex-direction: column;
    min-height: 100vh;
  `};
`;

export const ContainerCard = styled.div`
  flex: 0 0 auto;
  padding: 2rem 5vw;
  min-height: 67.5vh;
  position: relative;
  top: -20vh;
  margin-right: 2vw;
  background-color: #e3e3e3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const ContainerList = styled.div`
  flex: 1 1 auto;
  margin-left: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  ${MEDIA.DESKTOP`
    width: 100%;
    margin-top: -20vh;
  `};
`;

export const SpecialtiesContainer = styled.div``;

export const CardTitle = styled.h2`
  color: #1a486a;
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

export const CardDesc = styled.p`
  color: #000;
  margin-top: 1rem;
`;

export const ListRoot = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 80rem;
  ${MEDIA.DESKTOP`
    max-height: 75rem;
  `}
  ${MEDIA.TABLET`
    max-height: none;
  `}
`;

export const ListItem = styled.li`
  margin: 1rem;
  padding: 1rem 0;
  max-width: 15vw;
  ${MEDIA.DESKTOP`
    max-width: 35vw;
  `}
  ${MEDIA.TABLET`
    max-width: none;
  `}
  ::before {
    content: '-':
    margin-left: -2rem;
  }
`;

export const ListTitle = styled.h2`
  color: #3895db;
  font-size: 3rem;
  margin: 5rem 0 5rem 0;
  padding: 1rem 0;
  ${MEDIA.DESKTOP`
    text-align: center;
  `}
`;

export const ListSubTitle = styled.h2`
  color: #3895db;
  font-size: 2rem;
  margin: 1rem 0;
  padding: 1rem 0;
  ${MEDIA.TABLET`
    margin: 1rem auto;
  `}
`;

export const SubList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  line-height: 2rem;
  ${MEDIA.TABLET`
    max-width: none;
  `}
`;

export const Figure = styled.figure`
  width: 100vw;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100%;
  ${MEDIA.DESKTOP`
    width: 100vw;
  `};
`;

export const StyledImg = styled(Img)`
  display: block;
  width: 15vw;
  margin-bottom: 1rem;
  ${MEDIA.DESKTOP`
    width: 33vw;
  `};
  ${MEDIA.TABLET`
    width: 50vw;
  `};
  ${MEDIA.PHONE`
    width: 75vw;
  `};
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const QuoteText = styled.h3`
  font-style: italic;
  color: #1a486a;
  text-align: center;
`;

export const ListMarker = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  background-color: #3895db;
  border-radius: 50%;
  display: inline-block;
`;
