import styled from 'styled-components';
import Img from 'gatsby-image';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  font-size: 2.5rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;
  ${MEDIA.DESKTOP`
    margin-bottom: 4rem;
  `};
`;

export const StyledFigure = styled.figure`
  display: block;
  margin: 0 2vw;
`;

export const StyledFigCaption = styled.figcaption`
  max-width: 15vw;
  ${MEDIA.DESKTOP`
    max-width: none;
  `};
`;

export const StyledImg = styled(Img)`
  margin: auto;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  border: 2px solid rgba(0,0,0,0);
  transition: border 0.5s;
  transition: box-shadow 0.5s;
  /*Disabled hover after Vale's feedback
  ${StyledFigure}:hover & {
    border: 2px solid #3895db;
    box-shadow: 0px 0px 10px 0px #3895db;
  }*/
  ${MEDIA.DESKTOP`
    width: 50vw;
    height: 50vw;
    max-width: 50vw;
    max-height: 50vw;
  `};
`;
