import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import BackgroundSection from 'components/home/landing/background';
import Img from 'gatsby-image';

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-top: 15vh;

  background-color: #faf8f6;

  max-width: 100%;
  height: 85vh;

  ${MEDIA.DESKTOP`
    flex-direction: column;
    height: auto;
  `};
`;

export const Figure = styled.figure`
  width: 100vw;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100%;
  padding: 0;
  ${MEDIA.DESKTOP`
    width: 100%;
  `};
`;

export const Title = styled.span`
  color: #1a5d8f;
  max-width: 100%;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '400';
      default:
        return '500';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem';
      default:
        return '2rem';
    }
  }};
  text-align: center;
  line-height: 1.2;
  max-width: 100vw;
  ${MEDIA.DESKTOP`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        default:
          return '2rem';
      }
    }};
    background: rgba(250, 248, 246, 0.75);
    width: 100%;
  `};
`;

export const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  // These three crucial styles (if existing) are directly parsed and added to
  // the pseudo-elements without further ado (except when overwritten).
  background-repeat: none;
  background-position: right bottom;
  background-size: 50% auto;
  background-color: #faf8f6;
`;

export const StyledImg = styled(Img)`
  min-width: 10vw;
  display: block;
  max-width: 20vw;
  width: 100%;
  margin-bottom: 5rem;
  ${MEDIA.DESKTOP`
    width: 50vw;
    height: 50vw;
    max-width: 50vw;
    max-height: 50vw;
  `};
`;

export const StyledButtonsHolder = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  margin: 5rem 0;
  ${MEDIA.DESKTOP`
    max-width 80vw;
  `};
`;

export const StyledButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2%;
  color: #fff;
  max-width: 33%;
  /* height: 3rem; */
  font-weight: bold;
  text-decoration: none;
  border-radius: 0.5rem;
  background-color: #1a5d8f;
  &:hover {
    background-color: #d90368;
  }
  ${MEDIA.DESKTOP`
    padding: 1rem;
    width: 15vw;
  `};
  ${MEDIA.DESKTOP`
    width: auto;
  `};
`;

export const MobileLandingSection = styled.div`
  width: 100%;
  display: none;
  ${MEDIA.DESKTOP`
    display: flex;
  `};
`;
