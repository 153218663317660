import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';

import Landing from 'components/home/landing';
import Gallery from 'components/home/gallery';
//import IOExample from 'components/home/io-example';
import Spotlight from 'components/home/spotlight';
import Contacts from 'components/contacts/contacts';

import { graphql } from 'gatsby';

const Index = ({ data }) => {
  useEffect(() => {
    const scripts = {
      cookiePolicy: 'cookiepolicy.js',
      privacyPolicy: 'privacypolicy.js',
      banner: 'banner.js',
      iubendaCCPA: '//cdn.iubenda.com/cs/ccpa/stub.js',
      iubendaGDPR: '//cdn.iubenda.com/cs/iubenda_cs.js',
    };
    for (const fileLocation in scripts) {
      if (Object.hasOwnProperty.call(scripts, fileLocation)) {
        const addedScript = document.createElement('script');
        addedScript.src = scripts[fileLocation];
        scripts.async = true;
        document.body.appendChild(addedScript);
      }
    }
  });
  return (
    <Layout>
      <Landing
        message={data.homeJson.landing.message}
        image={data.homeJson.landing.image}
        logo={data.homeJson.landing.logo}
        telephone={data.homeJson.landing.telephone}
      />
      <Gallery items={data.homeJson.gallery} />
      {/*<div style={{ height: '50vh' }} />
      <IOExample />*/}
      <Spotlight
        image={data.homeJson.spotlight.photo}
        quote={data.homeJson.spotlight.quote}
        specialties={data.specializzazioniJson.specializzazioni}
      />
      <Contacts icons={data.footerJson.icons}></Contacts>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      landing {
        message
        logo {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      spotlight {
        quote
        photo {
          childImageSharp {
            fluid(maxWidth: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    specializzazioniJson {
      specializzazioni {
        title
        therapies
      }
    }
    footerJson {
      icons {
        title
        link
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
